import React, { Component } from 'react'
import { graphql } from 'gatsby'
import WatPage from 'src/components/WatPage'

import 'antd/dist/antd.css' // or 'antd/dist/antd.less'
import 'bootstrap/dist/css/bootstrap.css'
import 'static/shared-style/normalize.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const pageQuery = graphql`
  query {
    product: allMarkdownRemark(filter: { frontmatter: { layout: { eq: "product" } } }) {
      edges {
        node {
          id
          rawMarkdownBody
          frontmatter {
            best_seller
            category
            colorTone
            id
            image
            imageS3
            isShow
            price
            size
            slashPrice
            position
            descMobile
          }
        }
      }
    }
    watsList: allMarkdownRemark(filter: { frontmatter: { layout: { eq: "wat" } } }) {
      edges {
        node {
          frontmatter {
            name
            name2
            path
            group
            linkPosition
            landingPage {
              isShowOnLandingPage
              position
            }
          }
        }
      }
    }
  }
`

export default class Template extends Component {
  componentDidMount() {
    // window.location = '/wat-phra-sri'
  }
  render() {
    const { product, watsList } = this.props.data
    return (
      <WatPage
        wat={{ frontmatter: { name: '' } }}
        product={product}
        isHomepage={true}
        watsList={watsList}
      />
    )
  }
}
